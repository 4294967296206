import { defineStore } from 'pinia';
import { toast } from 'vue3-toastify';
import {
  catchErrorAndToast,
  formatDate,
  moveAndAdjustRank,
} from '@/utils/helpers';
import groupBy from 'lodash/groupBy';
import cloneDeep from 'lodash/cloneDeep';
import { useUsersStore } from './useUsers';
import { DEFAULT_QUERY, useQueryBuilderStore } from './useQueryBuilder';
import router from '@/router';
import { parseISO } from 'date-fns';
import { apiDelete, apiGet, apiPost, apiPut } from '@/utils/api';
import getPath from '@/utils/getPath';
import { nullDateIfNotValid } from '@/utils/helpers';

export const DEFAULT_STRATEGY_ID = 1;
export const END_OF_DAYS = '9999-12-31T00:00:00';

export const useGamePlanStore = defineStore('gameplans', {
  state: () => ({
    gameplans: [],
    selectedStrategyExecution: {},
    gameplansLoading: false,

    worklists: [],
    previous_worklists: [],
    worklistsLoading: false,
    execution_info: {},

    draftData: {},
    createDraftLoading: false,
    deleteDraftLoading: false,
    editMode: false,
    on: null, // this is the "on" Date coming in from queryParams

    deleteModal: {},
    logoutModal: false,
    timeoutModal: false,

    preview: [],
    previewLoading: false,

    isStrategyLocked: false,
    editingMessage: '',

    endOfDays: END_OF_DAYS,
  }),
  getters: {
    isCurrentGameplanSelected: state => {
      return (
        Object.keys(state.selectedStrategyExecution || {}).length &&
        state.selectedStrategyExecution.is_current
      );
    },
    getEditMode: state => {
      return state.editMode;
    },
    getWorkItemCount: state => {
      return state.selectedStrategyExecution?.total_work_items;
    },
    getTotalBalance: state => {
      return state.selectedStrategyExecution?.total_balance;
    },
    getCurrentExecId: state => {
      return state.execution_info?.execution_id;
    },
    getCurrentExecObject: state => {
      return state.gameplans.find(plan => plan.is_current === true);
    },
    getCurrentGameplan: state => {
      let currentGameplan = '';

      if (state.isCurrentGameplanSelected) {
        currentGameplan += 'Current';
      }

      if (Object.keys(state.selectedStrategyExecution || {}).length) {
        return `${currentGameplan ? `${currentGameplan} - ` : ''}${formatDate(
          state.selectedStrategyExecution.execution_date,
          'Pp',
          false
        )}`;
      } else return currentGameplan;
    },
    getGameplans: state => {
      function dateToKey(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      }

      return groupBy(state.gameplans, gameplan => {
        return dateToKey(parseISO(gameplan.execution_date));
      });
    },
    getSaveState() {
      return {
        work_strategy_draft_id: this.draftData.work_strategy_draft_id,
        work_list_order: this.worklists.map((el, index) => ({
          work_list_id: el.work_list_id,
          rank: index + 1,
        })),
      };
    },
  },
  actions: {
    setEditMode(editMode) {
      this.editMode = editMode;
    },
    setLockStatusAndMessage(lockStatus, message = '') {
      this.isStrategyLocked = lockStatus;
      this.editingMessage = message;
    },
    async resetWorklists() {
      this.getWorkStrategy(DEFAULT_STRATEGY_ID).then(response => {
        if (response.success) {
          this.getWorkLists(
            DEFAULT_STRATEGY_ID,
            response.data.strategy_executions[0].execution_id
          );
          this.selectStrategy(response.data.strategy_executions[0]);

          if (Object.keys(response.data?.lock_status || {}).length) {
            if (response.data.lock_status?.locked == true) {
              this.isStrategyLocked = true;
              this.editingMessage = `${response.data.lock_status?.locked_by} is currently editing the Game Plan.`;
            } else {
              this.isStrategyLocked = false;
              this.editingMessage = '';
            }
          }
        }
      });
    },
    async stepBackWorklists() {
      this.worklists = cloneDeep(this.previous_worklists);
    },
    async selectStrategy(strategy) {
      this.selectedStrategyExecution = strategy;
    },
    reorder(event, item, rank, fromIndex) {
      if (item.rank == rank) return;

      const fromItem = this.worklists[fromIndex];
      const toIndex = this.worklists.findIndex(el => el.rank == rank);
      const toItem = this.worklists[toIndex];

      if (toIndex === -1 || !toItem) {
        event.target.value = item.rank;
        return;
      }

      if (item.is_default || toItem.is_default) return;

      if (
        (toItem.is_priority && !item.is_priority) ||
        (!toItem.is_priority && item.is_priority)
      ) {
        event.target.value = item.rank;
        return;
      }

      this.worklists = moveAndAdjustRank(this.worklists, fromItem, toIndex);

      this.previous_worklists = cloneDeep(this.worklists);
    },
    async createDraftWorkStrategy() {
      try {
        this.createDraftLoading = true;

        const responseData = await apiPost(
          getPath(`strategy/${this.execution_info.work_strategy_id}/drafts`)
        );

        if (!responseData.success) {
          toast.error(responseData.message);
          return responseData;
        } else {
          this.draftData = responseData.data;
          this.editMode = true;
          return responseData;
        }
      } catch (err) {
        return await catchErrorAndToast(
          err,
          toast,
          'Not Authorized To Edit Game Plan'
        );
      } finally {
        this.createDraftLoading = false;
      }
    },
    async deleteDraftWorkStrategy() {
      this.deleteDraftLoading = true;
      try {
        const responseData = await apiDelete(
          getPath(
            `strategy/${this.execution_info.work_strategy_id}/drafts/${this.draftData.work_strategy_draft_id}`
          )
        );

        if (!responseData.success) {
          toast.error(responseData.message);
          return responseData;
        } else {
          this.draftData = responseData.data;
          this.editMode = false;
          return responseData;
        }
      } catch (err) {
        return await catchErrorAndToast(
          err,
          toast,
          'Not Authorized To Edit Game Plan'
        );
      } finally {
        this.deleteDraftLoading = false;
      }
    },
    async getWorkStrategy(strategyId, on) {
      this.gameplansLoading = true;
      try {
        const queryStringParameters = on
          ? new URLSearchParams({ on: on })
          : null;

        const responseData = await apiGet(
          getPath(`strategy/${strategyId}`),
          queryStringParameters
        );

        if (!responseData.success) {
          toast.error(responseData.message);
          return responseData;
        } else {
          this.gameplans = responseData.data.strategy_executions;
          this.execution_info.work_strategy_id =
            responseData.data.work_strategy_id;
          return responseData;
        }
      } catch (err) {
        return await catchErrorAndToast(
          err,
          toast,
          'Not Authorized To View Game Plan'
        );
      } finally {
        this.gameplansLoading = false;
      }
    },
    async saveWorkStrategy() {
      try {
        const payload = this.getSaveState;

        const responseData = await apiPut(
          getPath(`strategy/${this.execution_info.work_strategy_id}`),
          payload
        );

        if (!responseData.success) {
          toast.error(responseData.message);
          return responseData;
        } else {
          this.resetEditedStatus();
        }
      } catch (err) {
        toast.error(err.response?.data?.message ?? err.message);
        console.error(err.response ?? err);
        return err.response ?? err;
      }
    },
    async getWorkLists(strategyId, execId, queryTerms) {
      this.worklistsLoading = true;
      this.worklists = [];
      this.previous_worklists = [];
      this.execution_info = {};

      try {
        let execution_id = execId
          ? execId
          : this.selectedStrategyExecution.execution_id;

        let queryStringParameters = new URLSearchParams();
        if (queryTerms) queryStringParameters.append('filter', queryTerms);
        queryStringParameters.append('execId', execution_id);

        const responseData = await apiGet(
          getPath(`strategy/${strategyId}/worklists`),
          queryStringParameters
        );

        if (!responseData.success) {
          toast.error(responseData.message);
          return responseData;
        } else {
          // We need to adjust some returned worklist values
          const mappedWorklists = responseData.data.work_lists.map(
            (wl, index) => {
              return {
                ...wl,
                assignees: wl.assignees?.filter(e => e),
                rank: queryTerms ? wl.rank : index + 1,
                end_timest: nullDateIfNotValid(wl.end_timest),
                edited: false,
              };
            }
          );

          this.previous_worklists = cloneDeep(mappedWorklists);
          this.worklists = cloneDeep(mappedWorklists);

          this.execution_info = responseData.data.execution_info;
          this.execution_info.work_strategy_id =
            responseData.data.work_strategy_id;
          return responseData;
        }
      } catch (err) {
        const errorBody = await err.response.json();
        toast.error(errorBody?.message ?? 'Error Fetching Work List');
        console.error(errorBody?.message ?? errorBody);
        return errorBody;
      } finally {
        this.worklistsLoading = false;
      }
    },
    async editWorklist(worklist, query) {
      const queryBuilder = useQueryBuilderStore();
      if (!queryBuilder.disabledDuringAttempt) return;
      const users_store = useUsersStore();

      const body = {
        change_type: 'edit_work_list',
        payload: {
          work_list_id: worklist.work_list_id,
          work_list_name: worklist.work_list_name,
          description: worklist.description,
          owner: worklist.owner,
          start_timest: worklist.start_timest,
          end_timest: nullDateIfNotValid(worklist.end_timest),
          is_priority: worklist.is_priority,
          criteria_json: query ?? {
            ...DEFAULT_QUERY,
            valid: true,
          },
          assignees: worklist.assignees,
        },
      };

      try {
        const responseData = await apiPost(
          getPath(
            `strategy/${this.draftData.work_strategy_id}/drafts/${this.draftData.work_strategy_draft_id}/changes`
          ),
          body
        );

        if (!responseData.success) {
          toast.error(
            responseData.message || 'There was an error editing this worklist.'
          );
          return responseData;
        } else {
          const oldWorklist = this.worklists.find(
            el => el.work_list_id == worklist.work_list_id
          );

          // Update the rank in special cases
          if (oldWorklist.is_priority && !worklist.is_priority) {
            const wlIndex = this.worklists.findIndex(
              el => el.work_list_id == worklist.work_list_id
            );

            this.worklists = this.worklists
              .map((el, index) => {
                if (el.work_list_id == worklist.work_list_id) {
                  const locationOfDefault = this.worklists.findIndex(
                    el => el.is_default
                  );

                  return {
                    ...el,
                    ...responseData.data,
                    rank:
                      locationOfDefault !== -1
                        ? locationOfDefault
                        : this.worklists.length,
                    mod_by: users_store.activeUser?.username, // TODO: Confirm this is updated on the server
                    mod_timest: new Date(),
                    end_timest: nullDateIfNotValid(responseData.end_timest),
                    edited: true,
                  };
                } else if (index > wlIndex) {
                  return {
                    ...el,
                    rank: el.is_default ? index + 1 : index,
                  };
                } else {
                  return el;
                }
              })
              .sort((a, b) => a.rank - b.rank);
          } else if (!oldWorklist.is_priority && worklist.is_priority) {
            const wlIndex = this.worklists.findIndex(
              el => el.work_list_id == worklist.work_list_id
            );

            this.worklists = this.worklists
              .map((el, index) => {
                if (el.work_list_id == worklist.work_list_id) {
                  return {
                    ...el,
                    ...responseData.data,
                    owner: worklist.owner,
                    rank:
                      this.worklists.filter(el => el.is_priority).length + 1,
                    mod_by: users_store.activeUser?.username, // TODO: Confirm this is updated on the server
                    mod_timest: new Date(),
                    end_timest: nullDateIfNotValid(responseData.end_timest),
                    edited: true,
                  };
                } else if (!el.is_priority && index < wlIndex) {
                  return {
                    ...el,
                    rank: el.rank + 1,
                  };
                } else {
                  return el;
                }
              })
              .sort((a, b) => a.rank - b.rank);
          } else {
            this.worklists = this.worklists
              .map(el => {
                if (el.work_list_id == worklist.work_list_id)
                  return {
                    ...el,
                    ...responseData.data,
                    owner: worklist.owner,
                    mod_by: users_store.activeUser?.username, // TODO: Confirm this is updated on the server
                    mod_timest: new Date(),
                    end_timest: nullDateIfNotValid(responseData.end_timest),
                    edited: true,
                  };
                return el;
              })
              .sort((a, b) => a.rank - b.rank);
          }

          // Update the state of previous worklists
          this.previous_worklists = cloneDeep(this.worklists);

          router.push('/gameplan');
          return responseData;
        }
      } catch (err) {
        const errorBody = await err.response.json();
        if (err.response.status == 403)
          toast.error('Not Authorized To Edit GamePlan');

        if (err.response.status != 403) {
          toast.error(errorBody.message);
        }
        console.error(errorBody.message);
        return errorBody;
      } finally {
        queryBuilder.setDisabledDuringAttempt(false);
      }
    },
    async saveWorklist(worklist, query) {
      const queryBuilder = useQueryBuilderStore();
      if (!queryBuilder.disabledDuringAttempt) return;
      const users_store = useUsersStore();

      const body = {
        change_type: 'create_work_list',
        payload: {
          work_list_name: worklist.work_list_name,
          description: worklist.description,
          owner: worklist.owner,
          start_timest: worklist.start_timest,
          end_timest: worklist.end_timest,
          is_priority: worklist.is_priority,
          criteria_json: query,
          assignees: worklist.assignees,
        },
      };

      try {
        const responseData = await apiPost(
          getPath(
            `strategy/${this.draftData.work_strategy_id}/drafts/${this.draftData.work_strategy_draft_id}/changes`
          ),
          body
        );

        if (!responseData.success) {
          toast.error(
            responseData.message || 'There was an error saving this worklist.'
          );
          return responseData;
        } else {
          if (worklist.is_priority) {
            this.worklists = [
              ...this.worklists.filter(el => el.is_priority),
              {
                ...responseData.data,
                rank: this.worklists.filter(el => el.is_priority).length + 1,
                mod_by: users_store.activeUser?.username, // TODO: Confirm this is updated on the server
                mod_timest: new Date(),
                end_timest: nullDateIfNotValid(responseData.end_timest),
                edited: true,
              },
              ...this.worklists
                .filter(el => !el.is_priority)
                .map(el => ({
                  ...el,
                  rank: el.rank + 1,
                })),
            ].sort((a, b) => a.rank - b.rank);
          } else {
            const defaultWorklist = this.worklists.find(el => el.is_default);

            this.worklists = [
              ...this.worklists.map(e => ({
                ...e,
                rank: e.is_default ? e.rank + 1 : e.rank,
              })),
              {
                ...responseData.data,
                rank: defaultWorklist
                  ? defaultWorklist.rank
                  : this.worklists.length + 1,
                mod_by: users_store.activeUser?.username, // TODO: Confirm this is updated on the server
                mod_timest: new Date(),
                end_timest: nullDateIfNotValid(responseData.end_timest),
                edited: true,
              },
            ].sort((a, b) => a.rank - b.rank);
          }
          // Update the state of previous worklists
          this.previous_worklists = cloneDeep(this.worklists);

          router.push('/gameplan');
          return responseData;
        }
      } catch (err) {
        const errorBody = await err.response.json();
        if (err.status == 403) toast.error('Not Authorized To Edit Game Plan');

        if (err.status != 403) {
          toast.error(errorBody.message);
        }
        console.error(errorBody.message);
        return errorBody;
      } finally {
        queryBuilder.setDisabledDuringAttempt(false);
      }
    },
    async deleteWorklist(worklist) {
      const body = {
        change_type: 'delete_work_list',
        payload: {
          work_list_id: worklist.work_list_id,
        },
      };

      try {
        const responseData = await apiPost(
          getPath(
            `strategy/${this.draftData.work_strategy_id}/drafts/${this.draftData.work_strategy_draft_id}/changes`
          ),
          body
        );

        if (!responseData.success) {
          toast.error(responseData.message);
          return responseData;
        } else {
          const deletedIndex = this.worklists.findIndex(
            el => el.work_list_id == worklist.work_list_id
          );

          this.worklists = [
            ...this.worklists.slice(0, deletedIndex),
            ...this.worklists
              .slice(deletedIndex + 1, this.worklists.length)
              .map(e => ({
                ...e,
                rank: e.rank - 1,
              })),
          ];

          this.previous_worklists = cloneDeep(this.worklists);

          this.deleteModal = {};
          return responseData;
        }
      } catch (err) {
        const errorBody = await err.response.json();
        if (err.response.status == 403)
          toast.error('Not Authorized To Edit Game Plan');

        if (err.response.status != 403) {
          toast.error(errorBody.message);
        }
        console.error(errorBody.message);
        return err.response ?? err;
      }
    },
    async getWorkItemPreview(strategyId = DEFAULT_STRATEGY_ID, workListId) {
      this.previewLoading = true;
      this.preview = [];

      try {
        const responseData = await apiGet(
          getPath(`strategy/${strategyId}/worklists/${workListId}/preview`)
        );

        if (!responseData.success) {
          toast.error(responseData.message);
          return responseData;
        } else {
          this.preview = responseData.data;
          return responseData;
        }
      } catch (err) {
        const errorBody = await err.response.json();
        if (err.response.status == 403)
          toast.error('Not Authorized To View Game Plan');

        if (err.response.status != 403) {
          toast.error(errorBody.message);
        }
        console.error(errorBody.message);
        return errorBody;
      } finally {
        this.previewLoading = false;
      }
    },
    resetEditedStatus(boolean = false) {
      const mappedWorklists = this.worklists.map((wl, index) => {
        return {
          ...wl,
          edited: boolean,
        };
      });
      this.worklists = cloneDeep(mappedWorklists);
    },
    async testCriteria(query) {
      const body = {
        criteria_json: query,
      };

      try {
        const responseData = await apiPost(getPath(`utils/testCriteria`), body);

        if (!responseData.success) {
          toast.error(responseData.message);
          return responseData;
        } else {
          return responseData;
        }
      } catch (err) {
        toast.error(err.response?.data?.message ?? err.message);
        console.error(err.response ?? err);
        return err.response ?? err;
      }
    },
  },
});
